import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', padding: '20px', textAlign: 'center', marginTop: "170px" }}>
      <p>&copy; 2023 EAD Assignment</p>
    </footer>
  );
};

export default Footer;