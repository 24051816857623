import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => {
    const savedUserId = Cookies.get('userId');
    return savedUserId || null;
  });

  // Assuming you set UserType somewhere
  const [UserType, setUserType] = useState("travelagent");

  const setUser = (id, type) => {
    setUserId(id);
    setUserType(type);
    Cookies.set('userId', id, { expires: 7 });
  };

  return (
    <UserContext.Provider value={{ userId, UserType, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };